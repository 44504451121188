import * as React from "react";
import { SEO } from "../components/seo";
import { Layout } from "../components/layout";
import "./press.css";

const Press = () => {
  return (
    <Layout>
      <div className="press-wrapper">
        <h1>Press Clippings Coming Soon</h1>
      </div>
    </Layout>
  );
};

export default Press;

export const Head = () => <SEO title={"Press"} />;
